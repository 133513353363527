@import "variable.scss";
:root{
	--primary-color: #2cbcf4;
	--accent-color: #03238a;
	--light-color : #f6f8fa;
 }
/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("../src/assets/fonts/materialicons.woff2") format('woff2');
}
.mat-mdc-dialog-container{
	.mat-mdc-dialog-title{
		color: #FFF !important;
	}
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Roboto';
  src: url('../src/assets/fonts/Roboto-Regular.woff2') format('woff2'),
      url('../src/assets/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('../src/assets/fonts/Roboto-Medium.woff2') format('woff2'),
      url('../src/assets/fonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Light';
  src: url('../src/assets/fonts/Roboto-Light.woff2') format('woff2'),
      url('../src/assets/fonts/Roboto-Light.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url('../src/assets/fonts/Roboto-Bold.woff2') format('woff2'),
      url('../src/assets/fonts/Roboto-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Black';
  src: url('../src/assets/fonts/Roboto-Black.woff2') format('woff2'),
      url('../src/assets/fonts/Roboto-Black.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Thin';
  src: url('../src/assets/fonts/Roboto-Thin.woff2') format('woff2'),
      url('../src/assets/fonts/Roboto-Thin.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
.mat-toolbar{
	background: none !important;
}
body.mat-typography {
  margin: 0;
}
*{
    letter-spacing: normal;
    box-sizing: border-box;
}
p {
    font-size: 14px;
    line-height: 20px;
}
.accent-spinner{
	svg{
		stroke: var(--accent-color) !important;
	}
}
.color-picker-field{
	.mat-mdc-form-field {
		position: relative;
		.mat-mdc-form-field-subscript-wrapper {
			position: absolute;
		}
		.mat-mdc-text-field-wrapper {
			background: transparent;
			.mat-mdc-form-field-infix{
				padding: 11px 0px;
				min-height: auto !important;
			}
			.mdc-line-ripple {
				display: none;
			}
		}
	}
}
.my-show-table{
	.mat-mdc-form-field{
		.mat-mdc-text-field-wrapper{
			background: transparent !important;
			padding: 0;
			&:hover,&.mdc-text-field--filled{
				.mat-mdc-form-field-focus-overlay{
					opacity: 0 !important;
				}
			}
		}
	}
}

.row {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-flow: wrap;
}
.col-md-2 {
  width: 16.66666667%;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-md-3 {
  width: 25%;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-md-4 {
  width: 33.33333333%;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-md-5 {
  width: 41.66666667%;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-md-6 {
  width: 50%;
  flex: 0 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-7 {
  width: 58.33333333%;
  flex: 0 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-8 {
  width: 66.66666667%;
  flex: 0 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-9 {
  width: 75%;
  flex: 0 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-12 {
  flex: 0 0 auto;
  width: 100%;
}
.file-upload{
	margin-top: 10px;
}
.mat-mdc-dialog-container .mat-mdc-dialog-title {
    color: #fff !important;
}
.pl-0{
  padding-left: 0 !important;
}
.pr-0{
  padding-right: 0 !important;
}
.pt-0{
  padding-top: 0 !important;
}
.pb-0{
  padding-bottom: 0 !important;
}
.p-0{
  padding: 0 !important;
}
.w-100{
  width: 100% !important;
}
.position-relative{
  position: relative;
}
.m-0{
  margin: 0 !important;
}
.mr-2{
  margin-right: .5rem !important;
}
.ml-2{
  margin-left: .5rem !important;
}
.mt-2{
  margin-top: .5rem !important;
}
.mb-2{
  margin-bottom: .5rem !important;
}
.sidenav {
  width: 250px;
  background-color: #fff;
}
.side-logo-img {
  padding: 0px 20px;
  display: block;
  background-color: #fff;
  margin: 20px 0 20px 0;
  a {
    display: block;
		width: 100%;
    img {
      width: 100%;
    }
  }
}
.shows-btn button {
  margin-left: 5px;
}
.errors{
  color: red;
}
.smpt-offset-description{
	font-size: 12px;
	line-height: 1.2;
	margin-top: -14px !important;
}

.text-underline:hover{
	text-decoration: underline;
}
.main-menu{
  .mat-mdc-list-item {
    color: #000 !important;
	font-size: 18px !important;
	border-radius: 0;
    .mat-icon.notranslate.material-icons.mat-icon-no-color {
      margin-right: 10px;
    }
    &:hover{
      background-color: #000 !important;
      color: #fff !important;
    }
  }
}
.main-menu .mdc-list-item:hover .mdc-list-item__primary-text{
	color: #fff;
}
.edit-img-btn{
	top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto !important;
    transform: translateY(-50%) !important;
    position: absolute !important;
    display: inline-block !important;
    width: 160px;
}
.gray-btn {
	background: #bdbdbd !important;
}
.pricing-content {
    border: 2px solid var(--primary-color);
    h2{
        background-color: var(--primary-color) !important;
        border-bottom: 2px solid var(--primary-color);
        text-align: center;
    }
    p {
        color: #000;
        font-weight: 500;
    }
    .pricing-details{
        text-align: center;
        .green-btn {
            font-size: 24px !important;
            height: 45px !important;
            margin-bottom: 20px;
        }
        .pricing-table{
            tr{
                height: 40px;
                border:none !important;
                th{
                    text-align: center;
                    background-color: var(--primary-color);
                    color: #FFF;
                    font-size: 18px;
                    border: 1px solid  var(--primary-color);
                }
                td{
                    text-align: center;
                    color: #000;
                    font-weight: 500;
                    border: 1px solid  var(--primary-color) !important;
                }
            }
        }
    }
}
.green-btn {
	border: 2px solid !important;
	padding: 0px 20px !important;
}
.custom-heading {
    background: #D9D9D9;
    color: #000;
    padding-left: 10px;
    font-weight: 500 !important;
    margin-bottom: 30px !important;
}
.activated {
	padding: 0px 20px !important;
	text-transform: uppercase !important;
}
.primary-bg-color{
	background-color: var(--primary-color) !important;
	color: #FFF !important;
}
.popup-heading.name{
  padding: 0px 0px;
}
.popup-heading {
  background-color: var(--primary-color);
  padding: 20px;
  color: #fff;
  text-transform: uppercase;
  font-size: 22px !important;
  box-sizing: border-box;
  $font-family: Roboto, "Helvetica Neue", sans-serif;
	position: relative;
	button{
		color: var(--light-color) !important;
		border-color: var(--light-color) !important;
	}
}
.popup-heading.single-show .mat-mdc-dialog-actions{
	min-height: auto !important;
	margin-bottom: 0 !important;
}
.popup-heading.single-show{
	padding: 20px 260px 20px 20px;
}
.popup-heading.single-show .mat-mdc-dialog-actions {
  padding: 0 20px !important;
}
.mat-focus-indicator.mat-mdc-button.mat-mdc-button-base.cancel-popup {
  $font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-focus-indicator.mat-mdc-button.mat-mdc-button-base.cancel-popup.blue-color {
  background-color: var(--primary-color);
  color: #fff;
  $font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-focus-indicator.mat-mdc-button.mat-mdc-button-base.popup-save {
  background-color: var(--primary-color);
  color: #fff;
	border-color: var(--primary-color) !important;
  $font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-focus-indicator.mat-mdc-button.mat-mdc-button-base.popup-save.red-color {
  background-color: #ff0000;
  color: #fff;
  $font-family: Roboto, "Helvetica Neue", sans-serif;
}
.content {
  padding: 30px;
	@media (max-width: 800px){
		padding: 10px;
	}
}
.mat-mdc-paginator.hidden{
	display: none;
}
.progress{
	position: relative;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	button.cancel-upload{
		position: absolute;
		top: -20px;
		right:0;
	}
}
.ngx-mat-color-canvas .color-canvas-row .zone-inputs:last-child,
.ngx-mat-color-canvas .color-canvas-row:nth-of-type(2) .mat-mdc-form-field:last-of-type,
.ngx-mat-color-collection {
	display: none;
}
.my-show-table {
  .btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .table-search{
          display: block;
              max-width: 200px;
      }
  }
  table{
      width: 100%;
      tr.mat-mdc-header-row{
          background-color: var(--primary-color);
          th{
              color: #fff;
              font-size: 16px;
          }
          td{
              color: #000;
              font-size: 15px;
          }
      }
  }
}
.show-sequence{
	table{
		width: 100%;
		tr.mat-mdc-header-row{
			background-color: var(--primary-color);
			th{
				color: #fff;
				font-size: 16px;
			}
			td{
				color: #000;
				font-size: 15px;
			}
		}
	}
}
.mat-mdc-dialog-container{
  .mat-mdc-form-field{
    width: 100%;
  }
}
.popup-heading.single-show mat-dialog-actions.mat-mdc-dialog-actions {
  padding: 0 !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content{
	line-height: 36px;
}
.side-logo-img a img {
	width: 60px;
	display: block;
	margin: 0 auto;
}

.side-logo-img {margin: 0;}
.mat-mdc-dialog-content{
	max-height: 75vh !important;
}
#waveform wave {
	overflow: hidden !important;
	max-height: 100% !important;
}
.announcement-music-box{
	.music-box {
		width: 50%;
	}
}
.music-box {
  flex: 0 0 auto;
  padding: 50px 25px 10px 25px;
	text-align: center;

	&.settings-box{
		padding-top: 0;
	}
	h4 {
		font-weight: 700;
		font-size: 24px;
	}
	.musix-controls {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
    margin-top: 20px;
		h5 {
			margin: 0;
			padding: 0 15px;
			font-weight: bold;
			font-size: 18px;
		}
	}
	p{
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.announcement-music-box {
	display: flex;
	margin-left: -15px;
	margin-right: -15px;
	flex-flow: wrap;
	.music-box{	
		box-sizing: border-box;
	}
	.announcement-box{
		box-sizing: border-box;
	  width: 50%;
	  padding: 50px 25px 10px 25px;
	  box-sizing: border-box;
	  h4 {
		font-weight: 700;
		font-size: 24px;
		text-align: center;
	  }

	  .announcement-field{
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-appearance-outline .mat-form-field-infix {
        padding: 0 0 1em 0 !important;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-wrapper{
        padding-bottom: 0.74375em;
      }
      textarea{
        height: 50px;
        line-height: 1.4;
      }
	  }

	  .announcement-footer {
		padding-left: 10px;
		padding-right: 5px;
		display: flex;
		.announcement-chr-limit {
		  width: 40%;
		  display: flex;
		  align-items: center;
		  p {
			margin: 0 !important;
		  }
		}
		.announcement-btn {
		  display: flex;
		  width: 70%;
		}
		.announcement-btn button {
		  width: 100%;
		  height: 35px;
		  margin-left: 10px;
		}
	  }
	  .announcement-txt{
		text-align: center;
		margin-top: 25px;
	  }
	}
  }


.music-box{
	box-sizing: border-box;
	button{
		padding: 5px 20px;
	}
}
a{
	color: #000;
	&:hover{
		color: var(--primary-color);
	}
}
html{
	--mat-sidenav-container-width: 250px;
}
mat-expansion-panel-header.popup-heading mat-panel-title {color: #fff;}

mat-expansion-panel-header.popup-heading {height: 72px !important;background-color: var(--primary-color) !important;}

.welcome-message .mat-expansion-indicator::after {color: #fff;}

.welcome-message.mat-expansion-panel {border-radius: 0; margin-bottom: 20px;}
.toolbar-sm{
	display: none !important;
}
.hidden_dropdown .mat-mdc-paginator-page-size {
	display: none;
}
.mat-mdc-row {
	height: auto;
}
.mat-cell {
	padding: 8px 8px 8px 0 !important;
	&.mat-cell:first-of-type{
		padding-left: 16px !important;
		line-height: 30px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	}
}
.mat-mdc-cell{
	border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
	padding-top: 8px !important;
	padding-bottom: 8px !important;
	&.mat-mdc-cell:first-of-type{
		padding-left: 16px !important;
	}
}

.wave-controls {
	padding-bottom: 20px;
	padding-top: 20px;
	text-align: center;
	button {
		font-size: 36px;
		padding: 30px 25px !important;
		margin: 0 40px !important;
		height: auto;
	}
}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.add-smpte-chkbx .mat-checkbox-inner-container {
	width: 30px;
	height: 30px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.add-smpte-chkbx .mat-checkbox-layout .mat-checkbox-label {
	line-height: 30px;
}
p.filename {
	text-align: right;
	font-size: 11px;
	margin: 0;
	line-height: 16px;
}

div#waveform {
	border: solid 1px #eee;
	min-height: 128px;
}
div#wave-timeline{
	min-height: 20px;
}
.transparent {
	opacity: 0;
}
.hidden{
	display: none !important;
}
.wave-container {
	position: relative;
}

.progress-bar-container {
	position: absolute;
	width: 100%;
	top: 50%;
	transform: translateY(-50%);
	text-align: center;
	font-size: 1.5em;
}

.progress-bar-container .mat-mdc-progress-bar {
	margin-top: 10px;
}

.mat-focus-indicator.mat-mdc-button.mat-mdc-button-base {
	border: solid 1px;
}
.popup-heading.single-show .mat-mdc-dialog-actions {
	min-height: auto;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 18px;
}
.timer {
	font-size: 110px;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
	line-height: 110px;
}
.popup-heading p.filename {
	font-size: inherit;
	text-transform: initial;
	float: right;
	line-height: inherit;
}
.popup-heading.single-show > span{
	font-size: 12px;line-height: 15px;display: block;margin: 5px 0;
}
@media (max-width: 768px) {
	div#waveform {
		height: 60px;
		min-height: 60px;
		overflow: hidden;
	}
	.popup-heading.single-show span{
		display: block;
	}
	p.filename{
		display: block;
	}
	.popup-heading {
		padding-top: 55px;
		padding-right: 10px;
		font-size: 15px !important;
		padding-left: 10px;
		padding-bottom: 10px;
	}
	.mat-mdc-dialog-actions {
		top: 10px;
		right: 10px;
		transform: translateY(0);
	}
	.wave-controls {
		button {
			font-size: 24px;
			margin: 0 2.5% !important;
			width: 45%;
			box-sizing: border-box;
			padding: 30px 10px !important;
		}
	}
	.timer{
		font-size: 60px;
		line-height: 60px;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.wave-controls{
		padding-top: 0;
		padding-bottom: 20px;
	}
}
.mat-mdc-dialog-container{
	height: auto !important;
}
input[type="time"]::-webkit-calendar-picker-indicator {
    font-size: 21px;
    padding-right: 16px;
}
.countdown {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.9);
	z-index: 9999;
	> span{
		position: absolute;
		font-size: 30px;
		top: 50%;
		left: 50%;
		transform:translate(-50%,-50%);
		text-align: center;
		width: 90%;
		b{
			font-size: 100px;
			display: block;
			line-height: 120px;
		}
		button{
			margin: 10px;
			text-transform: uppercase;
			color: red;
		}
	}
}
.progress-bar-container .mat-mdc-progress-spinner {
	display: inline !important;
}
#edit-show {
    padding: 5px;
    border-radius: 0;
    border: solid 1px #000;
}
.page-settings{
	padding: 10px;
	.edit-fields {
		margin-bottom: 10px;
		label {
			display: block;
		}
		input {
			width: 100%;
			color: #fff;
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.color-picker-field {
	mat-form-field {
		border-radius: 4px;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mat-form-field-wrapper {
		margin: 0 !important;
		padding-bottom: 0;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mat-form-field-flex {
		padding: 0px 10px !important;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mat-form-field-infix {
		border-top: 0;
		padding: 11px 0;
	}
}
.font-picker-field{
	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mat-form-field-flex {
		padding: 0px 10px !important;
	}
}
@media (max-width: 500px) {
	.wave-controls {
		button {
			font-size: 14px;
			padding: 15px 5px !important;
		}
	}
	.mat-mdc-dialog-content {
    padding-bottom: 50px !important;
	}
	.mat-mdc-dialog-actions {
    transform: translateY(-50px);
    background: #FFF !important;
	}
	.popup-heading {
		padding: 2px 10px 10px !important;
		.mat-mdc-dialog-actions{
			position: relative;
			top: auto;
			right: auto;
			color: var(--primary-color);
			background: #fff;
			margin-bottom: 15px;
			padding-top: 10px !important;
			margin-left: -10px;
			margin-right: -10px;
			margin-bottom: -10px !important;
		}
	}
}
@media (max-width: 992px) {
  .announcement-box .announcement-footer .announcement-chr-limit {
    width: 60% !important;
  }
}
@media (max-width: 768px) {

  .announcement-music-box {
    display: block;
    .music-box {
      width: 100%;
    }
    .announcement-box{
      width: 100%;
      padding: 20px;
    }
  }

	.popup-heading.single-show {margin-top: 22px !important;}

	.popup-heading.single-show .mat-mdc-dialog-actions {position: absolute !important;top: -40px !important;right: 10px !important;}

	.popup-heading.single-show .mat-mdc-dialog-actions button {line-height: 22px;}
	.toolbar-sm{
		display: flex !important;
	}
	.music-box{
		padding-top: 0;
	}
	.mat-mdc-dialog-container{
		padding: 15px !important;
	}
	.popup-heading{
		padding: 15px !important;
	}
	.my-show-table .btn {
		display: block;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
	.my-show-table .btn .mat-button-toggle-group {
		margin-bottom: 1.34375em;
		/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
		.mat-button-toggle-appearance-standard .mat-button-toggle-label-content{
			padding: 0 8px;
		}
	}

	.add-btn {
		margin-bottom: 1.34375em !important;
	}
}
